<template>
  <div>
    <b-card>
      <b-row>
        <b-col class="col-lg-8 offset-lg-2">
          <app-timeline>

            <app-timeline-item
              v-for="(item, index) in items"
              :key="item.content"
              variant="warning"
              icon="MehIcon"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ item.appointment.customer.fullName }}</h6>
                <div>
                  <small class="text-muted mr-3">{{ moment(item.createdAt).format('D-M-Y HH:mm') }}</small>
                  <b-link
                    v-b-toggle="`feedback-info${index}`"
                  >
                    <feather-icon
                      icon="MoreHorizontalIcon"
                      size="18"
                      class="mr-50"
                    />
                  </b-link>
                </div>
              </div>
              <p>{{ item.content }}</p>
              <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start font-small-3">
                <!-- 1st Col -->
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">Medewerker</span>
                  <span>{{ item.appointment.employee.name }}</span>
                </div>
                <!-- 2nd Col -->
                <div class="mb-1 mb-sm-0">
                  <span class="text-muted mb-50 d-block">Dienst</span>
                  <span>{{ item.appointment.service.name }}</span>
                </div>
                <!-- 3rd Col -->
                <div>
                  <span class="text-muted mb-50 d-block">Datum en tijd</span>
                  <span>{{ moment(item.appointment.dateTime).format('D-M-Y HH:mm') }}</span>
                </div>
              </div>
              <b-collapse
                :id="`feedback-info${index}`"
                class="mt-1"
              >
                <b-button
                  size="sm"
                  variant="light"
                  class="mr-1"
                >
                  Verwijderen
                </b-button>
                <b-button
                  size="sm"
                  variant="light"
                  class="mr-1"
                >
                  Stuur bericht
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-primary"
                >
                  Verzoek publicatie
                </b-button>
              </b-collapse>
            </app-timeline-item>

          </app-timeline>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BLink, BCollapse, VBToggle,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import moment from 'moment'
import feedbackStoreModule from './feedbackStoreModule'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BCollapse,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      moment,
    }
  },
  setup() {
    const FEEDBACK_STORE_MODULE_NAME = 'feedback'
    const items = ref({})

    // Register module
    if (!store.hasModule(FEEDBACK_STORE_MODULE_NAME)) store.registerModule(FEEDBACK_STORE_MODULE_NAME, feedbackStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FEEDBACK_STORE_MODULE_NAME)) store.unregisterModule(FEEDBACK_STORE_MODULE_NAME)
    })

    store.dispatch('feedback/fetchData', { }).then(response => {
      items.value = response.data['hydra:member']
    })

    return {
      items,
    }
  },
}
</script>

<style>

</style>
