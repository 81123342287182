import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/feedback', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetch(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/feedback/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    create(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/feedback', queryParams)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
